import {Typography, Card, CardContent, CardMedia, makeStyles, Link} from '@material-ui/core';
import React from 'react';
import about from '../../styles/about';
import {devInfo} from '../../constants';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import GitHubIcon from '@material-ui/icons/GitHub';

const useStyles = makeStyles(about);

const InfoCard = ({person, reverse}) => {
  const classes = useStyles();
  const info = devInfo[person];
  return (
    <Card variant="outlined" className={classes.card}>
      <CardMedia
        component="img"
        className={classes.media}
        src={info.imgLink}
        title={info.name}
      />
      <CardContent style={{paddingTop: 0}}>
        <Typography gutterBottom variant="h6">
          {info.name}
        </Typography>
        <div className={classes.iconRow}>
          <Link href={info.linkedIn}>
            <LinkedInIcon/>
          </Link>
          <Link href={info.github}>
            <GitHubIcon/>
          </Link>
        </div>


        <Typography variant="body1">
          {info.desc}
        </Typography>
      </CardContent>
    </Card>

  );
};

export default InfoCard;

import {Paper, Container, useTheme, makeStyles, IconButton, Button, Select, Slider, Typography, TextField, InputLabel, FormControl} from '@material-ui/core';
import LocationIcon from '@material-ui/icons/MyLocation';

import React from 'react';
import moment from 'moment';

import LocationAutoComplete from './LocationAutoComplete';
import {durationOptions, distanceOptions} from '../../constants';
import inputarea from '../../styles/inputArea';
import {useStore} from '../../Store';

const useStyles = makeStyles(inputarea);

const InputArea = ({
  selectedDate,
  handleDateChange,
  duration,
  handleDurationChange,
  onSearchClick,
  showSearchButton,
  selectedAddress,
  handleSelectedAddress,
  showHiddenElements,
  hideHiddenElements,
  defaultShowSearch,
  radius,
  handleRadius,
  query,
  setQuery,
  setCurrLocationAsParam,
  currLocation,
  setUseCurrLocation,
}) => {
  const theme = useTheme();
  const factor = defaultShowSearch ? 6 : 8;
  const classes = useStyles({theme, factor, showSearchButton});
  const {store} = useStore();
  const valuetext = (value) => {
    return `${value}m`;
  };
  const generateRadiusLabel = (value, index) => {
    if (value < 1000) {
      return `${value}m`;
    } else {
      const newVal = (value / 1000).toFixed(1);
      return `${newVal}km`;
    }
  };

  const generateDurationLabel = (value) => {
    if (value < 60) {
      return `${value}min`;
    } else {
      const duration = moment.duration(value, 'minutes');
      return `${duration.hours()}h ${duration.minutes()}m`;
    }
  };

  return (
    <Paper className={classes.root} component={Container} variant="outlined">
      <div className={classes.timeWrapper}>
        <LocationAutoComplete selectedAddress={selectedAddress} handleSelectedAddress={handleSelectedAddress} query={query} setQuery={setQuery} currLocation={currLocation}/>
        <IconButton color={currLocation ? 'secondary' : 'primary' }disabled={!store.locationCapable} onClick={() => {
          if (!currLocation) {
            setCurrLocationAsParam();
          } else {
            setUseCurrLocation(false);
          }
        }}>
          <LocationIcon/>
        </IconButton>
      </div>
      {currLocation ? <Typography variant="subtitle2" color="secondary" className={classes.smallText}>Current Location being used</Typography> : ''}
      <div className={classes.timeWrapper}>
        <TextField
          className={classes.pickerInput}
          id="datetime-local"
          label="Start time"
          value={selectedDate}
          type="datetime-local"
          onChange={(e) => {
            handleDateChange(e.target.value);
            console.log(e.target.value);
          }}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <FormControl className={classes.select}>
          <InputLabel htmlFor="age-native-simple">Duration</InputLabel>
          <Select
            native
            value={duration}
            onChange={(e) => {
              handleDurationChange(e.target.value);
            }}
            inputProps={{
              name: 'duration',
              id: 'duration-native-select',
            }}
          >
            {durationOptions.map((opt, index) => {
              return <option key={index} value={opt}>{generateDurationLabel(opt)}</option>;
            })}
          </Select>
        </FormControl>
      </div>
      <div className={classes.slider}>
        <div className={classes.buttonHeader}>
          <Typography>
            Search radius
          </Typography>
        </div>
        <Slider
          defaultValue={500}
          color="primary"
          getAriaValueText={valuetext}
          valueLabelFormat={generateRadiusLabel}
          value={radius}
          onChange={(e, value) => handleRadius(value)}
          aria-labelledby="radius-slider"
          valueLabelDisplay="off"
          step={500}
          marks={distanceOptions.map((value) => {
            return {value, label: generateRadiusLabel(value)};
          })}
          min={500}
          max={2500}
        />
      </div>
      <Button onClick={onSearchClick} className={classes.button} color="secondary" variant="contained">Search</Button>
    </Paper>
  );
};

export default InputArea;

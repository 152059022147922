import {createMuiTheme} from '@material-ui/core/styles';

const theme = createMuiTheme({
  breakpoints: {
    xs: 0,
    sm: 800,
  },
  palette: {
    secondary: {
      light: '#00796b',
      main: '#ce6a85',
      dark: '#002984',
    },
    primary: {
      main: '#293745',
      light: '#e0f2ee',
      dark: '#5e35b1',
    },
  },
  typography: {
    // h6: '2.5rem',
    htmlFontSize: 17,
    fontFamily: [
      'Cairo',
      'Roboto',
      'sans-serif',
    ].join(','),
  },
  // overrides: {
  //   MuiGrid: {
  //     item: {
  //       padding: 'auto',
  //     }
  //   }
  // }
});

export default theme;

import {makeStyles} from '@material-ui/core';

import React, {useEffect} from 'react';
import InputArea from '../../components/InputArea';
import CarparkMap from './CarparkMap';

import results from '../../styles/results';
import CarparkList from '../../components/CarparkList';
import {useStore} from '../../Store';

const useStyles = makeStyles(results);

const ResultsMain = () => {
  const classes = useStyles();
  const {store, dispatch} = useStore();

  useEffect(() => {
    if (store.data.length === 0) {
      const resString = sessionStorage.getItem('results');
      if (resString) {
        const data = JSON.parse(resString);
        dispatch({type: 'STORE_DATA', payload: data.data});
      }
    }
    if (Object.keys(store.params.address).length === 0) {
      const addrString = sessionStorage.getItem('address');
      if (addrString) {
        store.params.address = JSON.parse(addrString);
        dispatch({type: 'SET_SEARCH', payload: store.params});
      }
    }
  }, [dispatch, store]);
  return (
    <div className={classes.root}>
      <InputArea defaultShowSearch={false} />
      <div className={classes.carparkRoot}>
        <CarparkMap />
      </div>
      <CarparkList />
    </div>
  );
};

export default ResultsMain;

import axios from 'axios';
import moment from 'moment';

import {baseUrl} from '../constants';

export default async (latStr, longStr, startTime, duration=60, radius=500) => {
  const durationTime = moment.duration(duration, 'minutes');
  const date = moment(startTime).format();
  const lat = parseFloat(latStr);
  const long = parseFloat(longStr);
  let durationString = durationTime.toISOString();
  durationString = durationString.split('PT')[1].toLowerCase();
  const res = await axios.post(`${baseUrl}api/getCarparks`, {lat, long, radius, date, duration: durationString});
  return res;
};

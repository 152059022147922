import React from 'react';
import {ReactTypeformEmbed} from 'react-typeform-embed';

// const useStyles = makeStyles(faq);

const ContactUs = () => {
  return (
    <div style={{display: 'block', position: 'relative', height: '70vh'}}>
      <ReactTypeformEmbed
        style={{
          position: 'absolute',
          top: '0px',
          left: '0px',
          width: '100vw',
          maxWidth: '800px',
          height: '70vh',
          overflow: 'hidden',
        }}
        url="https://carparkwhere2020.typeform.com/to/nfa95Mls" />
    </div>
  );
};

export default ContactUs;

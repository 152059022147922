import {Typography, Card, makeStyles} from '@material-ui/core';
import React from 'react';
import about from '../../styles/about';

const useStyles = makeStyles(about);

const NotFound = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Typography variant="h5" color="textSecondary" gutterBottom className={classes.title}>
        404 Not Found
        <hr />
      </Typography>
      <Typography paragraph className={classes.paragraph} align="justify">
        This route does not exist
      </Typography>
    </div>
  );
};

export default NotFound;

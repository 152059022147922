export default {
  root: {
    minHeight: '95vh',
    display: 'flex',
    flexDirection: 'column',
  },
  carparkRoot: {
    '& .leaflet-container': {
      width: '100%',
      height: '50vh',
    },
  },
  locationButton: {
    alignSelf: 'flex-end',
    marginRight: '1.3rem',
    marginTop: '-4.5rem',
    float: 'right',
    zIndex: 9999,
  },
};


import React from 'react';
import {TextField, makeStyles, useTheme} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

import {useLocationLoader} from '../../hooks/useLocationLoader';
import inputArea from '../../styles/inputArea';

const useStyles = makeStyles(inputArea);

const LocationAutoComplete = ({selectedAddress, handleSelectedAddress, query, setQuery, currLocation}) => {
  const [loading, mapResults] = useLocationLoader(query);

  const theme = useTheme();
  const classes = useStyles({theme});
  return (
    <Autocomplete
      className={classes.input}
      id="combo-box-demo"
      options={loading ? [{addr: 'No input'}] : mapResults}
      value={selectedAddress}
      freeSolo
      onChange={(event, newValue) => {
        if (newValue && !currLocation) {
          handleSelectedAddress(newValue);
        }
      }}
      inputValue={query}
      onInputChange={(event, newInputValue) => {
        if (!currLocation) {
          setQuery(newInputValue);
        }
      }}
      getOptionLabel={(option) => {
        if (option.addr) {
          return option.addr;
        }
        return '';
      }}
      renderInput={(params) => (<TextField
        {...params}
        id="outlined-basic"
        label="Address or Postal Code"
        variant="outlined"
        InputProps={{
          ...params.InputProps,
        }}
      />
      )}
    />
  );
};

export default LocationAutoComplete;

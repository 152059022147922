import React from 'react';
import {Container, Table, TableHead, TableRow, TableCell, TableBody, TableContainer, makeStyles, CardContent} from '@material-ui/core';
import carpark from '../../styles/carpark';

const useStyles = makeStyles(carpark);

const CostBreakdownDialog = ({breakdown}) => {
  const classes = useStyles();

  const parseTo12hr = (timeStr) => {
    const h = +timeStr.substr(0, 2);
    const hour = h % 12 || 12;
    const min = +timeStr.substr(2, 4);
    const ampm = (h < 12 || h === 24) ? 'am' : 'pm';
    const result = (min === 0) ? hour + ampm : hour + ':' + min + ampm;
    return result;
  };

  return (
    <Container className={classes.costBreakdownList}>
      <TableContainer className={classes.costBreakdown} component={CardContent} >
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Cost</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Timerange</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {breakdown.map((row, index) => (
              <TableRow key={index}>
                <TableCell>{row.Cost.toFixed(2)}</TableCell>
                <TableCell>{row.Description}</TableCell>
                <TableCell>{parseTo12hr(row.Timerangestr.Start)} to {parseTo12hr(row.Timerangestr.End)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default CostBreakdownDialog;

import moment from 'moment';

export const initialState = {
  loading: false,
  locationCapable: true,
  preLoaded: false,
  data: [],
  displayedCp: [],
  selectedCp: null,
  params: {
    address: {},
    start: moment().format(moment.HTML5_FMT.DATETIME_LOCAL),
    duration: 60,
    radius: 500,
  },
  currentPos: null,
};

export const reducer = (state, action) => {
  const {payload} = action;
  switch (action.type) {
    case 'DETERMINE_LOCATION_CAPABLE':
      state.locationCapable = payload;
      return Object.assign({}, state);
    case 'SET_PRELOADED':
      state.preloaded = payload;
      return Object.assign({}, state);
    case 'STORE_DATA':
      // Initialize carpark with empty array if api returns null
      state.data = payload || [];
      return Object.assign({}, state);
    case 'SET_LOADING':
      state.loading = payload;
      return Object.assign({}, state);
    case 'SET_SEARCH':
      state.params = payload;
      return Object.assign({}, state);
    case 'SET_DISPLAYED_CP':
      state.displayedCp = payload;
      return Object.assign({}, state);
    case 'SET_SELECTED_CP':
      state.selectedCp = payload;
      return Object.assign({}, state);
    default:
      return state;
  }
};


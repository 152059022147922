import React, {useState} from 'react';
import {Grid, Button, Card, Collapse, CardActions, CardContent, Typography, makeStyles} from '@material-ui/core';
import carpark from '../../styles/carpark';
import CostBreakdownDialog from './CostBreakdownDialog';
import {useStore} from '../../Store';

const useStyles = makeStyles(carpark);

const CarparkCard = ({name, cost, carparkType, gantryHeight, distance, lots, lat, long, costBreakdown}) => {
  const {dispatch} = useStore();
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const renderLotsInfo = (cpLots) => {
    return cpLots === '' ? 'No live lot data' : `${cpLots} lots available`;
  };
  const generateTextColor = (cpLots) => {
    if (cpLots==='') {
      return classes.blackText;
    } else if (cpLots>=100) {
      return classes.greenText;
    } else if (cpLots<100 && cpLots>=50) {
      return classes.orangeText;
    } else if (cpLots<50) {
      return classes.redText;
    }
  };

  const generateCPDescription = (carparkType, gantryHeight) => {
    if (gantryHeight==='') {
      return carparkType
    } else {
      return carparkType + ' | Gantry Height: ' + gantryHeight + 'm'
    }
  }

  const renderGmapLink = (lat, long) => {
    return `https://www.google.com/maps/dir/?api=1&destination=${lat},${long}&travelmode=driving`;
  };


  if (parseFloat(cost) < 0) {
    console.log('Issue with parsing cost: ', cost, ' for ', name);
    return '';
  }

  return (
    <Card className={classes.root} variant="outlined" onClick={() => {
      console.log('click card');
      dispatch({type: 'SET_SELECTED_CP', payload: null});
      dispatch({type: 'SET_SELECTED_CP', payload: {lat, long, name}});
    }}>
      <CardContent className={classes.cardContent}>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="subtitle1" color="textPrimary" align="left" className={classes.cpName}>{name}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle2" align="left">{generateCPDescription(carparkType,gantryHeight)}</Typography>
          </Grid>
          <Grid item xs={8} className={classes.descriptionContainer}>
            <Grid item xs={12}>
              <Typography variant="subtitle2" align="left">Distance: {parseFloat(distance).toFixed(2)}m</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle2" align="left" className={generateTextColor(lots)}>{renderLotsInfo(lots)}</Typography>
            </Grid>
          </Grid>
          <Grid item xs={4} className={classes.priceContainer}>
            <Typography variant="h5" color="textPrimary" align="right" className={classes.priceText} >${parseFloat(cost).toFixed(2)}</Typography>
          </Grid>
        </Grid>
      </CardContent>
      <CardActions className={classes.cardContent}>
        <Button size="small" variant="outlined" color="secondary" disabled={costBreakdown === null} onClick={() => setExpanded(!expanded)}>Cost Breakdown</Button>
        <Button size="small" variant="outlined" color="secondary" href={renderGmapLink(lat, long)} target="_blank">Open in Maps</Button>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CostBreakdownDialog breakdown={costBreakdown}/>
      </Collapse>
    </Card>
  );
};

export default CarparkCard;

import {Container} from '@material-ui/core';
import React from 'react';
import {BrowserRouter, Switch, Route} from 'react-router-dom';
import Header from './pages/Header';
import HomeMain from './pages/Home/HomeMain';
import ResultsMain from './pages/Results/ResultsMain';
import Loading from './components/Loading';
import AboutUs from './pages/About';
import ContactUs from './pages/ContactUs';
import app from './styles/app';
import Faq from './pages/Faq';
import NotFound from './pages/NotFound';

const App = () => {
  // Load store results from cookies if exist
  return (
    <Container disableGutters style={app}>
      <Loading />

      <BrowserRouter>
        <Header />
        <Switch>
          <Route path="/" exact component={HomeMain} />
          <Route path="/results" exact component={ResultsMain} />
          <Route path="/about" exact component={AboutUs} />
          <Route path="/faq" exact component={Faq} />
          <Route path="/contact" exact component={ContactUs} />
          <Route component={NotFound} />
        </Switch>
      </BrowserRouter>
    </Container>
  );
};

export default App;

import {Typography, makeStyles} from '@material-ui/core';
import React from 'react';
import faq from '../../styles/faq';
import FaqAccordion from './FaqAccordion';
import {faqContent, termsAndConditions} from '../../constants';

const useStyles = makeStyles(faq);

const Faq = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Typography variant="h5" color="textSecondary" gutterBottom className={classes.title}>
        FAQ
        <hr />
      </Typography>
      <div className={classes.accordionList}>
        {faqContent.map((faq) => <FaqAccordion title={faq.title} answer={faq.answer}/>)}
      </div>
      <br/><br/>
      <Typography variant="h5" color="textSecondary" gutterBottom className={classes.title}>
        Terms And Conditions
        <hr />
      </Typography>
      <Typography paragraph className={classes.paragraph} align="justify">
        {termsAndConditions}
      </Typography>
      {/* <Typography paragraph className={classes.paragraph} align="justify">
        Terms and Conditions paragraph 2
      </Typography>
      <Typography paragraph className={classes.paragraph} align="justify">
        Terms and Conditions paragraph 3
      </Typography> */}
    </div>
  );
};

export default Faq;

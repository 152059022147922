import {makeStyles, Container} from '@material-ui/core';
import React from 'react';
import InputArea from '../../components/InputArea';
import home from '../../styles/home';
import headerImg from '../../assets/carparkwhere_banner_compressed_v3.gif';
import AdSense from 'react-adsense';

const useStyles = makeStyles(home);

const HomeMain = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      {/* <Typography className={classes.label} align="right" variant="h4" color="textSecondary">CARPARK WHERE</Typography> */}
      <Container className={classes.header}>
        <img src={headerImg} alt="carparkwhere title" />
      </Container>
      {/* <div className={classes.header}>
      </div> */}
      <InputArea defaultShowSearch/>
      <Container>
        {/* Ad space */}
        <AdSense.Google
          client='ca-pub-8936879760209429'
          slot='7806394673'
          style={{display: 'block'}}
          layout='in-article'
          format='fluid'
        />
      </Container>
    </div>
  );
};

export default HomeMain;

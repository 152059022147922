import React from 'react';
// Local imports
import {StoreProvider} from './Store';

import {MuiPickersUtilsProvider} from '@material-ui/pickers';
// pick a date util library
import MomentUtils from '@date-io/moment';
import {ThemeProvider} from '@material-ui/core';
import theme from './styles/theme';

// Wrapper to allow root to access commons
const Root = ({children}) => {
  return (
    <StoreProvider>
      <ThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          {children}
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </StoreProvider>

  );
};

export default Root;

import {Grid, Paper, LinearProgress, Backdrop, makeStyles, Typography} from '@material-ui/core';
import React, {useState, useEffect} from 'react';
import {estimatedTimeFactor, gifMap} from '../../constants';
import loadingsheet from '../../styles/loading';
import {useStore} from '../../Store';

const useStyles = makeStyles(loadingsheet);

const Loading = () => {
  const {store} = useStore();
  const [loader, setLoader] = useState(null);
  const {loading} = store;
  const classes = useStyles();
  useEffect(() => {
    if (store.loading) {
      const max = Object.keys(gifMap).length;
      const rng = Math.floor(Math.random() * (max - 1 + 1) ) + 1;
      setLoader(rng);
    }
  }, [store.loading]);
  return (
    <Backdrop className={classes.backdrop} open={loading}>
      <Paper className={classes.paper}>
        <Grid item xs={12}>
          {setLoader ? <iframe title="loadingGif" src={gifMap[loader]} width="300" height="200" frameBorder="0" className={classes.gifloader}></iframe> : ''}

        </Grid>
        <Grid item xs={12}>
          <LinearProgress color="secondary" className={classes.loader}/>
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.text}>Estimated search/calculation time: {estimatedTimeFactor * store.params.radius / 1000}s</Typography>
        </Grid>
      </Paper>
    </Backdrop>
  );
};

export default Loading;

import React from 'react';
import {Link} from 'react-router-dom';
import {AppBar, Toolbar, Button, makeStyles} from '@material-ui/core';
import header from '../../styles/header';


const useStyles = makeStyles(header);

const Header = () => {
  const classes = useStyles();
  return (
    <div>
      <AppBar color="primary" position="static" className={classes.root} elevation={0}>
        <Toolbar>
          {/* <Icon classes={{root: classes.iconRoot}}>
            <img className={classes.imageIcon} src="assets/parking.svg" alt="carparkwhere"/>
          </Icon> */}
          <Button className={classes.linkButton} component={Link} color="inherit" to='/'>
            Search
          </Button>
          <Button className={classes.linkButton} component={Link} color="inherit" to='/about'>
            About
          </Button>
          <Button className={classes.linkButton} component={Link} color="inherit" to='/faq'>
            FAQ
          </Button>
          <Button className={classes.linkButton} component={Link} color="inherit" to='/contact'>
            Contact Us
          </Button>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default Header;

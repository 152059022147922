import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {useStore} from '../../Store';
import CarparkList from './CarparkList';


const CarparkListContainer = () => {
  const [sortMode, setSortMode] = useState(0);
  const {store, dispatch} = useStore();
  const [closedCarparksExist, setClosedCarparksExist] = useState(false);
  const [hideClosedCp, setHideClosedCp] = useState(true);

  useEffect(() => {
    let intermediateList = store.data;
    // Handle logic of hiding/showing closed carparks
    if (hideClosedCp) {
      intermediateList = intermediateList.filter((cp) => {
        const breakdown = cp.CostBreakdownList || [];
        // Filter out carpark if any 1 of the cost breakdown is closed
        return !breakdown.some((bd) => {
          if (bd.IsClosed) {
            setClosedCarparksExist(true);
          }
          return bd.IsClosed;
        });
      });
    }
    const slice = Math.min(intermediateList.length, 10);
    // Handle the sorting mode of the resultant list
    switch (sortMode) {
      case 0:
        intermediateList.sort((a, b) => a.Cost - b.Cost);
        // Only select top 10 maximum carparks
        intermediateList = intermediateList.slice(0, slice);
        dispatch({type: 'SET_DISPLAYED_CP', payload: []});
        dispatch({type: 'SET_DISPLAYED_CP', payload: intermediateList});
        break;
      case 1:
        intermediateList.sort((a, b) => a.Distance - b.Distance);
        // Only select top 10 maximum carparks
        intermediateList = intermediateList.slice(0, slice);
        dispatch({type: 'SET_DISPLAYED_CP', payload: []});
        dispatch({type: 'SET_DISPLAYED_CP', payload: intermediateList});

        break;
      default:
        // Only select top 10 maximum carparks
        intermediateList = intermediateList.slice(0, slice);
        dispatch({type: 'SET_DISPLAYED_CP', payload: []});
        dispatch({type: 'SET_DISPLAYED_CP', payload: intermediateList});
    }
  }, [store.data, sortMode, hideClosedCp, dispatch]);

  // Function to update sortMode when tab changes
  const handleChange = (event, newValue) => {
    setSortMode(newValue);
  };


  const componentProps = {
    sortMode,
    handleChange,
    hideClosedCp,
    setHideClosedCp,
    closedCarparksExist,
  };
  return (
    <CarparkList {...componentProps} />
  );
};

CarparkListContainer.propTypes = {
  sortMode: PropTypes.number,
  handleChange: PropTypes.func,
  hideClosedCp: PropTypes.bool,
  setHideClosedCp: PropTypes.func,
  closedCarparksExist: PropTypes.bool,
};

export default CarparkListContainer;

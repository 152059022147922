export default {
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    marginTop: '2rem',
  },
  card: {
    width: '250px',
    height: '300px',
    margin: '0 0.5rem 1rem 0.5rem',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  media: {
    height: '120px',
    width: 'auto',
    margin: '1rem auto',
  },
  devText: {
    margin: '0 2rem',
  },
  paragraph: {
    margin: '1rem 2rem',
  },
  iconRow: {
    display: 'flex',
    flexDirection: 'row',
    margin: 'auto',
    width: '25%',
    justifyContent: 'space-between',
  },
};

import {useState, useEffect} from 'react';
import axios from 'axios';

export const search = async (query) => {
  const {data} = await axios.get(
      'https://www.onemap.gov.sg/api/common/elastic/search',
      {
        params: {
          searchVal: query,
          returnGeom: 'Y',
          getAddrDetails: 'Y',
          pageNum: '1',
        },
      },
  );
  return data;
};

export const useLocationLoader = (query) => {
  const [loading, setLoading] = useState(true);
  const [mapResults, setMapResults] = useState([{addr: 'No entries'}]);
  useEffect(() => {
    async function getMapResults() {
      try {
        setLoading(true);
        const results = await search(query);
        setLoading(false);
        const finalResults = results.results.map((result) => {
          const val = {lat: result.LATITUDE, long: result.LONGITUDE, addr: result.ADDRESS};
          return val;
        });
        setMapResults(finalResults);
      } catch {
        setLoading(false);
        setMapResults([{addr: 'Not found'}]);
      }
    }
    if (query !== '') {
      getMapResults();
    }
  }, [query]);
  return [loading, mapResults];
};


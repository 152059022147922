
export default {
  root: {
    flexDirection: 'row',
    width: '100%',
  },
  title: {
    flexGrow: 1,
  },
  mainButton: {
    height: '48px',
    width: '48px',
    marginLeft: '8px',
  },
  toolbarButtons: {
    marginLeft: 'auto',
  },
  linkButton: {
    margin: '0px 6px',
    padding: '6px 6px 3px 6px',
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,0.09)',
      color: 'white',
    },
  },
  imageIcon: {
    height: '100%',
  },
  iconRoot: {
    textAlign: 'center',
    marginRight: '1rem',
  },
};

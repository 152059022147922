import React, {useReducer} from 'react';
import StoreContext from './StoreContext';
import {initialState, reducer} from './storeInits';


export const StoreProvider = ({children, subappName=null}) => {
  if (subappName) {
    StoreContext.displayName = `${subappName}Store`;
  }
  const [store, dispatch] = useReducer(reducer, initialState);
  return <StoreContext.Provider value={{store, dispatch}}>{children}</StoreContext.Provider>;
};

export {default as useStore} from './useStore';

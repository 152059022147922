import {Typography, makeStyles} from '@material-ui/core';
import React from 'react';
import about from '../../styles/about';
import InfoCard from './InfoCard';
import {aboutInfo} from '../../constants';

const useStyles = makeStyles(about);

const AboutUs = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Typography variant="h5" color="textSecondary" gutterBottom className={classes.title}>
        What is carparkwhere?
        <hr />
      </Typography>
      <Typography paragraph className={classes.paragraph} align="justify">
        {aboutInfo}
      </Typography>
      <Typography variant="h5" color="textSecondary" gutterBottom className={classes.title}>
        Developers
        <hr />
      </Typography>
      <InfoCard person="ay" reverse={false}/>
      <InfoCard person="ds" reverse={true}/>
    </div>
  );
};

export default AboutUs;

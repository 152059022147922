import {Typography, Button} from '@material-ui/core';

import React from 'react';
import {Map, Marker, Popup, TileLayer, Circle} from 'react-leaflet';
import L from 'leaflet';
import {useStore} from '../../Store';

const RedIcon = new L.Icon({
  iconUrl: 'assets/custom-icon.svg',
  iconRetinaUrl: 'assets/custom-icon.svg',
  iconAnchor: [15, 40],
  popupAnchor: [5, -32],
  iconSize: [27, 58],
  shadowUrl: '../assets/marker-shadow.png',
  shadowSize: [55, 70],
  shadowAnchor: [15, 62],
});

const BlueIcon = new L.Icon({
  iconUrl: 'assets/custom-icon-2.svg',
  iconRetinaUrl: 'assets/custom-icon-2.svg',
  iconAnchor: [15, 40],
  popupAnchor: [5, -32],
  iconSize: [27, 58],
  shadowUrl: '../assets/marker-shadow.png',
  shadowSize: [55, 70],
  shadowAnchor: [15, 62],
});

const DefaultIcon = new L.Icon.Default();

const CarparkMap = () => {
  const {store} = useStore();
  const generateLat = () => {
    return store.params.address.lat ? parseFloat(store.params.address.lat) : 1.3521;
  };

  const generateLong = () => {
    return store.params.address.long ? parseFloat(store.params.address.long) : 103.8198;
  };
  const renderGmapLink = (lat, long) => {
    return `https://www.google.com/maps/dir/?api=1&destination=${lat},${long}&travelmode=driving`;
  };
  const center = [generateLat(), generateLong()];
  return (
    <>
      <Map center={center} zoom={15}>
        <TileLayer
          attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {store.displayedCp.map((cp, index) => {
          if (store.selectedCp) {
            if (store.selectedCp.lat === cp.Lat && store.selectedCp.long === cp.Long && store.selectedCp.name === cp.Name) {
              console.log(store.selectedCp);
              return (
                <Marker icon={RedIcon} position={[parseFloat(cp.Lat), parseFloat(cp.Long)]} onClick={(e) => e.target.openPopup()} key={index}>
                  <Popup>
                    <Typography variant="subtitle2">{cp.Name}</Typography>
                    <Typography variant="subtitle2">${cp.Cost.toFixed(2).toString()}</Typography>
                    <Button size="small" variant="outlined" color="secondary" href={renderGmapLink(cp.Lat, cp.Long)}>Open in Maps</Button>
                  </Popup>
                </Marker>
              );
            }
          }
          return (
            <Marker icon={DefaultIcon} position={[parseFloat(cp.Lat), parseFloat(cp.Long)]} onClick={(e) => e.target.openPopup()} key={index}>
              <Popup>
                <Typography variant="subtitle2">{cp.Name}</Typography>
                <Typography variant="subtitle2">${cp.Cost.toFixed(2).toString()}</Typography>
                <Button size="small" variant="outlined" color="secondary" href={renderGmapLink(cp.Lat, cp.Long)}>Open in Maps</Button>
              </Popup>
            </Marker>
          );
        })}
        {/* Original search location */}
        <Marker icon={BlueIcon} position={[generateLat(), generateLong()]} onClick={(e) => e.target.openPopup()}>
          <Popup>
            <Typography variant="subtitle2">Your search location</Typography>
          </Popup>
        </Marker>
        {/* Circle radius */}
        <Circle center={[generateLat(), generateLong()]} color="blue" radius={store.params.radius || 0} stroke={false} fillOpacity={0.2}/>
      </Map>
    </>
  );
};

export default CarparkMap;

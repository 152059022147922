// Use fixed hostname during development environment i.e. npm start
export const baseUrl = process.env.NODE_ENV === 'development' ? 'http://localhost:8080/' : '';
// Generate array of 30
export const durationOptions = [...Array(14).keys()].map( (i) => i * 30);
export const distanceOptions = [...Array(5).keys()].map( (i) => (i+1) * 500);
export const estimatedTimeFactor = 6;
export const devInfo = {
  'ay': {
    name: 'Ang Yang',
    desc: 'Full-time software engineer, part-time Masters student at Georgia Tech',
    linkedIn: 'https://www.linkedin.com/in/yang-ang-025',
    github: 'https://github.com/AkaHitsuji',
    imgLink: 'angyang.png',
  },
  'ds': {
    name: 'De Sheng',
    desc: 'DevOps engineer by trade, fullstack engineer by hobby.',
    linkedIn: 'https://www.linkedin.com/in/chuan-de-sheng-67a424136/',
    github: 'https://github.com/dschuan',
    imgLink: 'desheng.png',
  },
};

export const gifMap = {
  1: 'https://giphy.com/embed/NfY2xu127irrG',
  2: 'https://giphy.com/embed/QBd2kLB5qDmysEXre9',
  3: 'https://giphy.com/embed/12zV7u6Bh0vHpu',
  4: 'https://giphy.com/embed/3ov9jWu7BuHufyLs7m',
};

export const aboutInfo = `Carparkwhere is a free service that helps you find the NEAREST and CHEAPEST parking spots in Singapore. Carparkwhere looks through all car parking charges in Singapore, searching rates and lot availability from over 3600 carparks, including major Shopping Malls, Office Buildings, Hotels, HDB and URA carparks. We developed this service as we realised that there is no real, easy to access solution that finds the nearest carparks and calculates the cost given a specific time duration. This matters to cost conscious drivers as carpark costs can add up over time. Through testing this service with users we found that this solution can potentially save drivers up to 30% of the potential cost they will have paid for a more expensive carpark. We hope this service will be able to help users bridge that information gap, and make parking more accessible to all Singaporeans :)`;

export const faqContent = [
  {title: `Do you collect user data?`, answer: `We do not under any circumstance collect any user data.`},
  {title: `Why can't I find the place I'm searching for?`, answer: `For best results, search using postal codes. Our search engine is powered by onemap: https://www.onemap.sg/home/`},
  {
    title: `Why do you not have the details of the carpark at (insert location name)`,
    answer: `As we are a small 2 man team, we automate as much of the data gathering process as we can. 
    We compile carpark information from known sources (government and commercial) and clean it into our own data format 
    for our service to perform its calculations on. As such, the completeness of our information depends on the sources we get our information from. 
    We do our best to keep this information up to date and as complete as possible. If you experience any discrepancy, 
    or you have the details of a carpark you would like to be included in this service, do fill up the typeform under the contact page and we will attend to it 
    as soon as possible!`,
  },
  {
    title: `This service is great! How can I support this initiative / contribute to this project?`,
    answer: 'You can reach out to us under the "Contact Us" tab above. We welcome all support and feedback! Thank you messages or reviews are also welcome!',
  },
];

export const termsAndConditions = `Carparkwhere is a free service and we strive to be as accurate and as complete as possible, however, there may be gaps at times with the information we provide. In using this service, you will not hold the developers of this service liable for incorrect information provided, including but not limited to, incorrect carpark costs, incorrect opening and closing times, incorrect calculated costs, incorrect carpark descriptions. You will do your own due dilligence to ensure that the returned carpark information provided is accurate and complete, park within the hours specified by the owner of the carpark, and make payment for parking according to the information provided at the carpark location.`;

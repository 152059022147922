import {Button, makeStyles, useTheme} from '@material-ui/core';
import React from 'react';
import inputarea from '../../styles/inputArea';

const useStyles = makeStyles(inputarea);

const ShowFieldButton = ({showInputArea, clickToToggleInput}) => {
  const theme = useTheme();
  const classes = useStyles({theme});
  return (
    <Button
      onClick={clickToToggleInput}
      className={classes.showInput}
      size="small"
      variant="contained"
      color="secondary"
    >
      {showInputArea ? 'Hide Search Fields' : 'Change Search Fields'}
    </Button>
  );
};

export default ShowFieldButton;

export default {
  root: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '0.6rem',
    paddingBottom: '0.5rem',
    borderWidth: 2,
    borderRadius: 5,
    // backgroundColor: '#ECECEC',
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: '0.5rem',
    paddingBottom: '0.1rem',
  },
  costBreakdownList: {
    width: '100%',
    paddingLeft: '0px',
    paddingRight: '0px',
  },
  costBreakdown: {
    width: 'auto',
    padding: '0.5rem',
    paddingBottom: '0.1rem',
  },
  redText: {
    color: 'red',
  },
  orangeText: {
    color: 'orange',
  },
  greenText: {
    color: 'green',
  },
  blackText: {
    color: 'black',
  },
  cpName: {
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '20px',
  },
  descriptionContainer: {
    paddingTop: '10px',
  },
  priceContainer: {
    position: 'relative',
  },
  priceText: {
    fontSize: '30px',
    position: 'absolute',
    bottom: 0,
    right: 0,
  },

};

export default {
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '10vh',
    paddingTop: (props) => `${props.factor * 2}px`,
    paddingBottom: (props) => `${props.factor * 2}px`,
    marginLeft: 0,
    marginRight: 0,
    padding: '0.5rem 0',
    color: (props) => props.theme.palette.text.secondary,
    alignItems: 'center',
    justifyContent: 'center',
  },
  timeWrapper: {
    marginTop: (props) => `${props.factor * 3}px`,
    display: 'flex',
    flexDirection: 'row',
    width: '90%',
    justifyContent: 'space-between',
  },
  buttonHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  input: {
    '& .MuiOutlinedInput-input': {
      color: (props) => props.theme.palette.text.secondary,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: (props) => props.theme.palette.text.secondary,
        color: (props) => props.theme.palette.text.secondary,
      },
      color: (props) => props.theme.palette.text.secondary, // or black
    },
    width: '90%',
    marginTop: (props) => `${props.factor * 5}px`,
    marginBottom: (props) => `${props.factor * 2}px`,
    marginLeft: 0,
    marginRight: 0,
  },
  smallText: {
    alignSelf: 'flex-start',
    marginLeft: '7.5%',
  },

  pickerInput: {
    '& .MuiTextField': {
      color: (props) => props.theme.palette.text.secondary,
    },
    '& fieldset': {
      borderColor: (props) => props.theme.palette.text.secondary,
      color: (props) => props.theme.palette.text.secondary, // or black
    },
    '& input': {
      color: (props) => props.theme.palette.text.secondary, // or black
    },
    color: (props) => props.theme.palette.text.secondary, // or black
    width: '65%',
    marginBottom: (props) => `${props.factor * 2}px`,
  },
  button: {
    marginTop: (props) => `${props.factor * 2}px`,
    // color: (props) => props.theme.palette.text.secondary, // or black
    width: '60%',
  },
  slider: {
    width: '90%',
    color: (props) => props.theme.palette.text.secondary, // or black
    '& .MuiTypography-root': {
      marginTop: (props) => props.showSearchButton ? '0.6rem' : 0,
    },
    '& .MuiSlider-root': {
      width: '85%',
      marginLeft: '7.5%',
    },
    '& .MuiSlider-active .MuiSlider-valueLabel': {
      fontSize: '0.6rem',
      fontWeight: 400,
      transform: 'scale(1.6) translateY(-10px) !important',
    },
    '& .MuiSlider-valueLabel': {
      fontSize: '0.6rem',
      fontWeight: 400,
    },
  },
  select: {
    width: '30%',
  },
  showInput: {
    borderRadius: 0,
    width: 'auto',
    height: '1.4rem',
    alignSelf: 'center',
    position: 'absolute',
    zIndex: '501',
  },
};

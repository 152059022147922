import React, {useState, useEffect} from 'react';
import {Collapse} from '@material-ui/core';
import moment from 'moment';
import PropTypes from 'prop-types';
import InputArea from './InputArea';
import {useStore} from '../../Store';
import getCarparks from '../../utils/getCarparks';
import {search} from '../../hooks/useLocationLoader';

import {useHistory} from 'react-router-dom';
import ShowFieldButton from './ShowFieldButton';

const InputAreaContainer = ({defaultShowSearch, errorMsgHandler=null}) => {
  const history = useHistory();
  const {dispatch, store} = useStore();
  const [selectedAddress, handleSelectedAddress] = useState({});

  let initialQuery;
  if (selectedAddress) {
    initialQuery = selectedAddress.addr || '';
  } else {
    initialQuery = '';
  }
  const [query, setQuery] = useState(initialQuery);
  const [selectedDate, handleDateChange] = useState(moment().format(moment.HTML5_FMT.DATETIME_LOCAL));
  const [duration, handleDurationChange] = useState(60);
  const [showInputArea, handleShowInputArea] = useState(defaultShowSearch);
  const [currLocation, setUseCurrLocation] = useState(false);
  const [radius, handleRadius] = useState(500);

  useEffect(() => {
    if (!defaultShowSearch) {
      handleDateChange(store.params.start);
      handleDurationChange(store.params.duration);
      handleSelectedAddress(store.params.address);
    }
  }, [defaultShowSearch, store.params.start, store.params.duration, store.params.address, dispatch, store.locationCapable, store.preloaded]);

  const onSearchClick = (e) => {
    dispatch({type: 'SET_LOADING', payload: true});
    console.log(selectedAddress);

    if (!query || query === '') {
      dispatch({type: 'SET_LOADING', payload: false});
      alert('You have not set a query!');
    } else {
      if (Object.keys(selectedAddress).length === 0 || selectedAddress.length === 0) {
        search(query).then((res) => {
          if (res.found === 0) {
            alert(`No results found for "${query}"`);
            dispatch({type: 'SET_LOADING', payload: false});
          } else {
            console.log(res);
            const result = res.results[0];
            const parsedAddress = {lat: result.LATITUDE, long: result.LONGITUDE, addr: result.ADDRESS};
            carparkAction(parsedAddress);
          }
        }).catch((err) => {
          console.log(err);
          carparkAction({});
        });
      } else {
        carparkAction(selectedAddress);
      }
    }
  };

  const carparkAction = (parsedAddress) => {
    console.log('Getting carparks');
    getCarparks(parsedAddress.lat, parsedAddress.long, selectedDate, duration, radius).then((res) => {
      dispatch({type: 'SET_LOADING', payload: false});
      dispatch({type: 'STORE_DATA', payload: res.data.carparks});
      const resString = JSON.stringify({data: res.data.carparks});
      sessionStorage.setItem('results', resString);
      sessionStorage.setItem('address', JSON.stringify(parsedAddress));
      dispatch({type: 'SET_SEARCH', payload: {address: parsedAddress, start: selectedDate, duration, radius}});
      dispatch({type: 'SET_SELECTED_CP', payload: null});
      if (defaultShowSearch) {
        history.push('/results');
      } else {
        handleShowInputArea(false);
      }
    }).catch((err) => {
      console.log(err.response);
      dispatch({type: 'SET_LOADING', payload: false});
      alert('There was a problem getting carpark data. Please refresh page and try again. If problem persists, do drop us an email at carparkwhere2020@gmail.com with your search parameters. Thank you!');
      if (errorMsgHandler) {
        errorMsgHandler('Error getting carpark data. Please refresh page and try again. If problem persists, do drop us an email at carparkwhere2020@gmail.com with your search parameters. Thank you!');
      }
    });
  };

  const setCurrLocationAsParam = () => {
    setUseCurrLocation(true);
    // console.log('hello');
    dispatch({type: 'SET_LOADING', payload: false});
    if (store.locationCapable) {
      if ('geolocation' in navigator) {
        /* geolocation is available */
        navigator.geolocation.getCurrentPosition(function(position) {
          const currAddr = {
            lat: position.coords.latitude,
            long: position.coords.longitude,
            addr: 'Your current location',
          };
          handleSelectedAddress(currAddr);
          dispatch({type: 'SET_LOADING', payload: false});
          if (!currAddr.lat || !currAddr.long) {
            setUseCurrLocation(false);
            alert('Location query failed. Try again');
            setQuery('');
          } else {
            setQuery('Your current location');
          }
        }, (err) => {
          setUseCurrLocation(false);
          dispatch({type: 'SET_LOADING', payload: false});
          dispatch({type: 'DETERMINE_LOCATION_CAPABLE', payload: false});
          if (err.code === 1) {
            alert(`Location is disabled. ${err.message}`);
          }
        });
      } else {
        dispatch({type: 'SET_LOADING', payload: false});
        dispatch({type: 'DETERMINE_LOCATION_CAPABLE', payload: false});
        alert('Location is disabled on your device.');
      }
    }
  };

  const clickToToggleInput = () => {
    if (!defaultShowSearch) {
      handleShowInputArea(!showInputArea);
    }
  };

  const containerProps = {
    selectedDate,
    handleDateChange,
    duration,
    setCurrLocationAsParam,
    handleDurationChange,
    onSearchClick,
    showInputArea,
    handleShowInputArea,
    selectedAddress,
    handleSelectedAddress,
    defaultShowSearch,
    radius,
    handleRadius,
    query,
    setQuery,
    currLocation,
    setUseCurrLocation,
  };

  const returnComponent = () => {
    if (defaultShowSearch) {
      return <InputArea {...containerProps} />;
    } else {
      return (
        <>
          <ShowFieldButton showInputArea={showInputArea} clickToToggleInput={clickToToggleInput} />
          <Collapse in={showInputArea}>
            <InputArea {...containerProps} />
          </Collapse>
        </>
      );
    }
  };

  return (
    <>
      {returnComponent()}
    </>
  );
};

InputAreaContainer.propTypes = {
  selectedDate: PropTypes.instanceOf(Date),
  handleDateChange: PropTypes.func,
  duration: PropTypes.number,
  handleDurationChange: PropTypes.func,
  onSearchClick: PropTypes.func,
  showInputArea: PropTypes.bool,
  selectedAddress: PropTypes.object,
  handleSelectedAddress: PropTypes.func,
  defaultShowSearch: PropTypes.bool,
  radius: PropTypes.number,
  handleRadius: PropTypes.func,
  query: PropTypes.string,
  setQuery: PropTypes.func,
  setCurrLocationAsParam: PropTypes.func,
  currLocation: PropTypes.bool,
  setUseCurrLocation: PropTypes.func,
};
export default InputAreaContainer;

export default {
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  label: {
    margin: '3rem 0.5rem 3rem 0',
  },
  // desheng's naming convention MUST FOLLOW
  header: {
    maxWidth: '800px',
    width: '100vw',
    '& img': {
      maxWidth: '100%',
      maxHeight: '100%',
    },
    paddingTop: '10px',
  },
  // COLON NOT EQUAL. sagely advice mate
};

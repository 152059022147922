import React from 'react';
import {Container, Tabs, Tab, Typography, Link} from '@material-ui/core';

import CarparkCard from './CarparkCard';
import Scrollbar from 'react-scrollbars-custom';
import Loading from '../Loading';
import {useStore} from '../../Store';

const CarparkList = ({sortMode, handleChange, hideClosedCp, setHideClosedCp, closedCarparksExist}) => {
  const {store} = useStore();
  const carparkList = store.displayedCp;
  const {loading, data} = store;

  const recommendedCarpark = () => {
    return data.filter((cp) => {
      return cp.IsRecommended;
    });
  };
  const renderRecommended = () => {
    const cp = recommendedCarpark();
    return cp.length > 0 ? (
      <>
        {!hideClosedCp ? (
            <Typography component={Link} onClick={() => setHideClosedCp(true)} color="secondary" variant="caption">
              Hide Closed Carparks
            </Typography>
          ) : ''}
        <CarparkCard name={cp[0].Name} cost={cp[0].Cost} carparkType={cp[0].Type} gantryHeight={cp[0].GantryHeight}distance={cp[0].Distance} lots={cp[0].AvailableLots}
          lat={cp[0].Lat} long={cp[0].Long} costBreakdown={cp[0].CostBreakdownList} />
      </>
      ) : (
        <>
          <Typography variant="subtitle2">No recommended carparks found</Typography>
          {/* Below condition is true if closed carparks are in the results and are filtered */}
          {closedCarparksExist ? (
            <Typography component={Link} onClick={() => setHideClosedCp(false)} color="secondary" variant="caption">
              <u>Returned results contain carparks that are closed within selected duration. Click to show these carparks but park at your own risk. </u>
            </Typography>) : ''}
        </>);
  };

  return loading ? <Loading /> : (
    <Container style={{height: '100%', paddingLeft: '0px', paddingRight: '0px'}}>
      <Scrollbar disableTracksWidthCompensation style={{width: '100%', height: '60vh'}}>
        <Typography variant="h6" color="textPrimary" style={{marginTop: '0.5rem', paddingLeft: '16px', fontWeight: '700'}}>Recommended Carpark</Typography>
        <Container>
          {renderRecommended()}
        </Container>
        <Tabs
          value={sortMode}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          <Tab label="By Cost" />
          <Tab label="By Distance"/>
        </Tabs>
        <Container>
          {carparkList.map((cp, index) => <CarparkCard
            key={index} name={cp.Name} cost={cp.Cost} carparkType={cp.Type} gantryHeight={cp.GantryHeight} distance={cp.Distance} lots={cp.AvailableLots}
            lat={cp.Lat} long={cp.Long} costBreakdown={cp.CostBreakdownList}/>)}
        </Container>
      </Scrollbar>

    </Container>

  );
};

export default CarparkList;

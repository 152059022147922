export default {
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  accordionList: {
    width: '90%',
  },
  title: {
    marginTop: '2rem',
  },
  paragraph: {
    width: '90%',
    margin: '1rem 1rem',
  },
};

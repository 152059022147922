export default {
  backdrop: {
    zIndex: 1000,
    width: '100vw',
    height: '110vh',
    backgroundColor: 'rgba(0,0,0,0.3)',
    color: '#fff',
  },
  paper: {
    display: 'flex',
    textAlign: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    alignContent: 'center',
    width: '90vw',
    maxWidth: '500px',
    paddingTop: '50px',
    height: '250px',
    position: 'absolute',
    top: '45%',
    transform: 'translateY(-50%)',
  },
  gifloader: {
    margin: 'auto',
  },
  loader: {
    width: '300px',
    height: '10px',
    borderRadius: '5px',
    margin: 'auto',
  },
  text: {
    marginBottom: '20%',
    marginTop: '1rem',
  },
};
